import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import { language, urlOfHost } from './atoms';

const MoreFiltersOfPhases = (props) => {
  const [lang,setLang] = useRecoilState(language);
  const [linkOfHost,] = useRecoilState(urlOfHost);
    const [teams,setTeams] = useState([]);
    const [salesReps, setSalesReps] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [activeFilter, setActiveFilter] = useState(null); // الحالة الجديدة لتحديد الخيار المفتوح
    const dropdownRef = useRef(null);
    const url = `${linkOfHost}/auth/assigned-sales-reps/`
    const url1 = `${linkOfHost}/teams-management/teams/`
    const getAllSalesReps = () => {
    axios.get(url).then((res) => {
        setSalesReps(res.data);
    }).catch(err => {
        console.log(err);
    });
};
    const getAllTeams = () => {
    axios.get(url1).then((res) => {
        setTeams(res.data.results);
    }).catch(err => {
        console.log(err);
    });
};

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setActiveFilter(null); 
  };

  const handleSubMenuClick = (filter) => {
    if (activeFilter === filter) {
      setActiveFilter(null); 
    } else {
      setActiveFilter(filter); 
    }
  };

  const handleFilterSelect = () => {
    props.setClearFilter(true);
    setIsOpen(false); 
    setActiveFilter(null);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
      setActiveFilter(null);
    }
  };

  useEffect(() => {
    getAllTeams();
    getAllSalesReps();
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        id="dropdownAvatarNameButton"
        onClick={toggleDropdown}
        className="flex items-center text-sm pe-1 font-medium text-white md:me-0"
        type="button"
      >
        <span className="sr-only">Open Sources menu</span>
        <span className="font-bold">{lang==='En'?'More Filters':'المزيد من الفلاتر'}</span>
        <svg
          className="w-2.5 h-2.5 ms-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>

      {isOpen && (
        <div
          id="dropdownAvatarName"
          className={`absolute top-7 ${lang==='En'?'left-[-13px]':'right-[-13px]'} z-[2] bg-gray-50 divide-y divide-gray-100 rounded-lg shadow w-44`}
        >
          <ul className="py-2 text-sm text-gray-700">
            <li className="hover:bg-gray-100 cursor-pointer" onClick={() => handleSubMenuClick('city')}>
              <div className="block px-4 py-2 text-gray-900">{props.labelOfTeamFilter}</div>
              {activeFilter === 'city' && (
                <ul
                    className={`absolute top-0 ${lang==='En'?'left-[180px]':'right-[180px]'} max-h-44 overflow-y-auto bg-gray-50 rounded-lg shadow w-48 py-2 text-sm text-gray-700`}
                >
                    {
                        teams.map((team)=>{
                            return(
                                <li key={team.id}
                                className="hover:bg-gray-100 cursor-pointer px-4 py-2"
                                onClick={() => {
                                    props.setValueOfTeamFilter(team.id)
                                    handleFilterSelect()
                                    props.setLabelOfTeamFilter(team.name)
                                    }}>
                                    {team.name}
                                </li>
                            )
                        })
                    }
                </ul>
            )}
            </li>
            <li className="hover:bg-gray-100 cursor-pointer" onClick={() => handleSubMenuClick('sales_Rep')}>
              <div className="block px-4 py-2 text-gray-900"><button>{props.labelOfSalesRepFilter}</button></div>
              {activeFilter === 'sales_Rep' && (
                <ul
                  className={`absolute top-0 ${lang==='En'?'left-[180px]':'right-[180px]'} bg-gray-50 rounded-lg shadow overflow-y-auto max-h-44 w-48 py-2 text-sm text-gray-700`}
                >{
                    salesReps.map((salesRep)=>{
                        return(
                            <li key={salesRep.id} onClick={()=>{
                              handleFilterSelect()
                              props.setLabelOfSalesRepFilter(salesRep.full_name_en)
                              props.setValueOfSalesRepFilter(salesRep.id);
                            }}
                            className="hover:bg-gray-100 cursor-pointer px-4 py-2"
                            >
                                <button>{salesRep.full_name_en}</button>
                        </li>
                        )
                    })
                }
                </ul>
              )}
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default MoreFiltersOfPhases;
