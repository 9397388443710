import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { useRecoilState } from "recoil";
import Swal from "sweetalert2";
import { language, urlOfHost } from "../components/atoms";

export default function CreateTeam() {
    const [lang,setLang] = useRecoilState(language);
    const [linkOfHost,] = useRecoilState(urlOfHost);
    const navigation = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [salesManagers, setSalesManagers] = useState([]);
    const [TeamNameErr, setTeamNameErr] = useState('');
    const [leader,setLeader] = useState()
    const [teamName,setTeamName] = useState('')
    const handleInputChange = (e, setState, setErrorState) => {
        setState(e.target.value);
        e.target.style.border = '1px solid #3b82f6';
        setErrorState('');
    };
    const getAllTeamLeaders = () => {
        axios.get(`${linkOfHost}/auth/sales-managers/`).then((res) => {
            setSalesManagers(res.data)
            setLeader(res.data[0].id);
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setIsLoading(false);
        });
    };

    useEffect(() => {
        getAllTeamLeaders()
    }, []);
    return (
        <center className="flex flex-col pt-10 min-h-screen items-center">
            <div className="w-full max-w-lg border-[1px] border-indigo-300 mb-1">
                {isLoading && (
                    <div className="fixed inset-0 bg-[#D1C4E9] bg-opacity-50 flex items-center justify-center z-50">
                        <ClipLoader color="blue" loading={isLoading} size={150} />
                    </div>
                )}
                <form method="POST" onSubmit={(e) => {
                    e.preventDefault();
                }} className="bg-white shadow-md rounded px-8 pt-6 pb-8">
                    <h1 className="mb-5 font-bold leading-tight tracking-tight md:text-xl">{lang==='En'?'Create a Team':'إنشاء فريق'}</h1>
                    <div className="mb-4">
                        <label className={`${lang==='En'?'text-left':'text-right'} block text-gray-700 text-sm font-openSansBold mb-2`} htmlFor="teamName">
                            {lang==='En'?'Team Name':'اسم الفريق'}
                        </label>
                        <input
                            onChange={(e)=>{
                                handleInputChange(e,setTeamName,setTeamNameErr)
                            }}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 focus:outline-none"
                            id="teamName"
                            type="text"
                            placeholder={`${lang==='En'?'Enter a Team Name':'أدخل اسم الفريق'}`}
                        />
                        <p id="userP" className={`${lang==='En'?'text-left pl-1':'text-right pr-1'} text-[#CE4343] text-sm `}>{TeamNameErr}</p>
                    </div>
                    <div className="mb-6">
                        <label className={`${lang==='En'?'text-left':'text-right'} block text-gray-700 text-sm font-openSansBold mb-2`} htmlFor="password">
                            {lang==='En'?'Team Leader':'قائد الفريق'}
                        </label>
                        <select  onChange={(e)=>{
                            setLeader(e.target.value);
                        }} className=" w-full bg-blue-500 text-white py-2 px-2 rounded-lg">
                            {
                                salesManagers.map((leader)=>{
                                    return(
                                        <option value={Number(leader.id)} key={leader.id} className="text-black bg-gray-200">{leader.full_name_en}</option>
                                    )
                                })
                            }
                        </select>                        
                    </div>
                    <div className="flex items-center justify-between">
                        <button onClick={(e)=>{
                        
                            setIsLoading(true);
                            axios.post(`${linkOfHost}/${lang==='En'?'en':'ar'}/teams-management/teams/`,{
                                name:teamName,
                                leader:leader
                            }).then((res)=>{
                                Swal.fire({
                                    icon:"success",
                                    title:`${lang==='En'?'The team has been successfully created':'تم إنشاء الفريق بنجاح'}`,
                                    confirmButtonText : `${lang==='En'?'Ok':'حسنا'}`
                                })
                                navigation('/teams')
                            }).catch((err)=>{
                                if(err.response){
                                    console.log(err)
                                    if(err.response.data.name){
                                        setTeamNameErr(err.response.data.name);
                                        document.getElementById("teamName").style.border = '1px solid #CE4343';
                                    }
                                }
                            }).finally(()=>{
                                setIsLoading(false);
                            })
                        }} className="bg-blue-500 hover:bg-blue-600 text-white font-openSansBold py-2 px-4 rounded focus:outline-none" type="button">
                            {lang==='En'?'create':'إنشاء'}
                        </button>
                    </div>
                </form>
            </div>
        </center>
    );
}
