import axios from "axios";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import Swal from "sweetalert2";
import { language, urlOfHost } from "../components/atoms";
import { useRecoilState } from "recoil";


export default function RejectDeal ({setRejectDeal,setChangeDetail,changeDetail}){
    const [lang,setLang] = useRecoilState(language);
    const [linkOfHost,] = useRecoilState(urlOfHost);
    const [descErr, setDescErr] = useState('');
    const params = useParams ();
    const [reason,setReason] = useState('Budget Constraints');
    const [isLoading,setIsLoading] = useState(false);
    const [otherReason , setOtherReason ] =useState(null);
    const url = `${linkOfHost}/${lang==='En'?'en':'ar'}/contacts-services/contacts/${[params.contactId]}/deals/${params.dealId}/reject_deal/`
    const handleInputChange = (e, setErrorState) => {
        e.target.style.border = '1px solid #3b82f6'; 
        setErrorState(''); 
    };

    const handelReject = ()=> {
        setIsLoading(true)
        axios.post(url,{
            reason:reason,
            description:otherReason
        }).then((res)=>{
            setChangeDetail(!changeDetail)
            setRejectDeal(false);
            Swal.fire({
                icon:"success",
                title:`${lang==='En'?'The deal was rejected successfully':'تم رفض الصفقة بنجاح'}`,
                confirmButtonText : `${lang==='En'?'Ok':'حسنا'}`
            })
        }).catch((err)=>{
            if(err.response){
                Object.keys(err.response.data).forEach(key => {
                    if (key === "description") {
                        const reasonInput = document.getElementById("reason");
                        reasonInput.style.border = '1px solid red';
                        setDescErr(err.response.data.description[0]);
                    }
                });
            }else{
                Swal.fire({
                    title:`${err.meassage}`,
                    icon:"error"
                })
            }
        }).finally(()=>{
            setIsLoading(false)
        })
    }
    return(
        <div className="fixed inset-0 z-50 flex p-0 m-0 items-center justify-center bg-black bg-opacity-50">
        <div className="p-6 w-[450px]">
            {isLoading && (
                <div className="fixed inset-0 bg-opacity-50 flex items-center justify-center z-50">
                    <ClipLoader color="blue" loading={isLoading} size={150} />
                </div>
            )}
            <form
            method="POST"
            onSubmit={(e) => {
                e.preventDefault();
            }}
            className="bg-white px-8 pt-6 pb-8"
            >
            <h1 className={`mb-3 font-bold leading-tight tracking-tight md:text-xl`}>
                {lang==='En'?'Reject a Deal':'رفض الصفقة'}
            </h1>
            <p className=" font-medium leading-tight tracking-tight md:text-lg mb-3">{lang==='En'?'the reason of reject':'أسباب الرفض'} :</p>
                <select onChange={(e)=>{
                    setReason(e.target.value);
                    if(e.target.value!=='Other'){
                        setOtherReason(null);
                    }
                }} className=" w-full bg-blue-500 text-white py-2 px-2 rounded-lg mb-6">
                    <option value='Budget Constraints' className=" text-black bg-gray-200 ">Budget Constraints</option>
                    <option value= 'Change In Priorities' className=" text-black bg-gray-200">Change In Priorities</option>
                    <option value='Lack of Interest'  className=" text-black bg-gray-200 ">Lack of Interest</option>
                    <option value='Competitor Selected' className=" text-black bg-gray-200 ">Competitor Selected</option>
                    <option value='Timeline Issue' className=" text-black bg-gray-200 ">Timeline Issue</option>
                    <option value='Other' className=" text-black bg-gray-200 ">Other</option>
                </select>
                {
                    reason==='Other' &&(
                        <div className="mb-4">
                        <label
                        className={`block ${lang==='En'?'text-left':'text-right'} text-gray-700 text-sm font-openSansBold mb-2`}
                        htmlFor="product Price"
                        >
                            {lang==='En'?'The Reason':'السبب'} 
                        </label>
                        <textarea
                        onChange={(e)=>{
                            setOtherReason(e.target.value)
                            handleInputChange(e,setDescErr);
                        }}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 focus:outline-none"
                        id="reason"
                        type="text"
                        placeholder={`${lang==='En'?'Enter a reason':'أدخل السبب'}`}
                        />
                        <p className={`${lang==='En'?'pl-1 text-left':'text-right pr-1'} text-red-500 text-sm`}>{descErr}</p>
                    </div>
                    )
                }
                <div className="flex items-center">
                <button onClick={handelReject}
                    className={`${lang==='En'?'mr-4':'ml-4'} bg-blue-500 hover:bg-blue-600 text-white font-openSansBold py-1 px-4 rounded focus:outline-none`}
                    type="button"
                >
                    {lang==='En'?'Reject':'رفض'}
                </button>
                <button onClick={()=>{
                    setRejectDeal(false)
                }}
                    className="bg-red-500 hover:bg-red-600 text-white font-openSansBold py-1 px-4 rounded focus:outline-none"
                    type="button"
                >
                    {lang==='En'?'Cancel':'الغاء'}
                </button>
                </div>
    </form>
    </div>
</div>
    )
}