import { useRecoilState } from "recoil";
import { language } from "../components/atoms";


export default function Error404() {
    const [lang,setLang] = useRecoilState(language);
    return(
        <div className="grid place-items-center h-screen">
            <div className="font-bold text-5xl flex items-center justify-center">
                <span className="text-red-400 mr-3">404</span> {lang==='En'?'Not found':'الصفحة غير موجودة.'}
            </div>
        </div>
    )
}