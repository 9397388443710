import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { language } from "../components/atoms";

export default function MainOfDealDetail() {
    const [lang,setLang] = useRecoilState(language);
    const location = useLocation();
    const params = useParams();

    // دالة للتحقق من التاب النشط
    const isActiveTab = (tab) => {
        if (location.pathname === `/contacts/contactDetail/${params.contactId}/dealsOfContacts/dealDetail/${params.dealId}` && tab === 'general') {
            return true;
        }
        return location.pathname.includes(tab);
    };
    return (
        <div className={`${lang==='En'?'sm:ml-0':'sm:mr-0'} mr-6 ml-6  mb-5`}>
            <div className="flex flex-row mt-7">
                <Link to={'general'}>
                    <button
                        id="general"
                        className={`px-4 link font-medium rounded-md border-solid border-2 py-1 
                        ${isActiveTab('general') ? 'bg-blue-600 border-blue-600 text-white' : 'text-gray-900 border-gray-800 hover:bg-blue-700 hover:text-white hover:border-transparent'}`}
                    >
                        {lang==='En'?'General':'عام'}
                    </button>
                </Link>
                <Link to={'phases'}>
                    <button
                        id="phases"
                        className={`px-4 link ${lang==='En'?'ml-2 sm:ml-6':'mr-2 sm:mr-6'}  font-medium rounded-md border-solid border-2 py-1 
                        ${isActiveTab('phases') ? 'bg-blue-600 border-blue-600 text-white' : 'text-gray-900 border-gray-800 hover:bg-blue-700 hover:text-white hover:border-transparent'}`}
                    >
                        {lang==='En'?'Phases':'مراحل'}
                    </button>
                </Link>
                <Link to={'notes'}>
                    <button
                        id="notes"
                        className={`px-4 link ${lang==='En'?'ml-2 sm:ml-6':'mr-2 sm:mr-6'} font-medium rounded-md border-solid border-2 py-1 
                        ${isActiveTab('notes') ? 'bg-blue-600 border-blue-600 text-white' : 'text-gray-900 border-gray-800 hover:bg-blue-700 hover:text-white hover:border-transparent'}`}
                    >
                        {lang==='En'?'Notes':'ملاحظات'}
                    </button>
                </Link>
                <Link to={'reminders'}>
                    <button
                        id="reminder"
                        className={`px-4 link ${lang==='En'?'ml-2 sm:ml-6':'mr-2 sm:mr-6'} font-medium rounded-md border-solid border-2 py-1 
                        ${isActiveTab('reminders') ? 'bg-blue-600 border-blue-600 text-white' : 'text-gray-900 border-gray-800 hover:bg-blue-700 hover:text-white hover:border-transparent'}`}
                    >
                        {lang==='En'?'Reminders':'تذكيرات'}
                    </button>
                </Link>
                <Link to={'clientInfo'}>
                    <button
                        id="clientInfo"
                        className={`px-4 link ${lang==='En'?'ml-2 sm:ml-6':'mr-2 sm:mr-6'} font-medium rounded-md border-solid border-2 py-1
                        ${isActiveTab('clientInfo') ? 'bg-blue-600 border-blue-600 text-white' : 'text-gray-900 border-gray-800 hover:bg-blue-700 hover:text-white hover:border-transparent'}`}
                    >
                        <span className={`${lang==='En'?'mr-[5px]':'ml-[2px]'}`}>{lang==='En'?'Cleint':' معلومات '}</span><span>{lang==='En'?'Info':' العميل '  }</span>
                    </button>
                </Link>
            </div>
            <hr className="mt-5 mb-3" />
            <Outlet />
        </div>
    );
}
