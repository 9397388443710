import { useRecoilState } from "recoil";
import { language } from "../components/atoms";

export default function NotAllowed(){
    const [lang,setLang] = useRecoilState(language);
    return(
        <div className="grid place-items-center h-screen">
            <div className="font-bold text-5xl flex items-center justify-center">
                <span className="text-red-400 mr-3">403</span> {lang==='En'?'You are not allowed to access this page.':'غير مسموح لك الوصول لهذه الصفحة .'}
            </div>
        </div>
    )
}