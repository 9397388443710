import axios from "axios";
import { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { useRecoilState } from "recoil";
import { language, urlOfHost } from "./atoms";

export default function TopThree(){
  const [lang, setLang] = useRecoilState(language);
  const [linkOfHost] = useRecoilState(urlOfHost);
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState([]);
  const url = `${linkOfHost}/statistics/top-3-selling-cities-statistics/`;
  const getInfo = async () => {
    try {
      const response = await axios.get(url);
      const data = response.data;
      setInfo(data);
      setLoading(true);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getInfo();
  }, []);
  return (
    <div className="bg-white rounded-xl duration-300 hover:scale-95 shadow-lg hover:shadow-xl w-full mix-w-[440px]">
      {loading ? (
        <div className="flex justify-center items-end gap-x-2 mt-40">
          {/* المركز الثاني */}
          {info.length > 1 ? (
            <div className="relative flex flex-col justify-center items-center">
              <div className=" flex flex-col items-center justify-center bg-gradient-to-r from-gray-300 to-gray-400 lg1:w-36 w-32 h-44 rounded-t-md shadow-md transition-transform transform hover:scale-105">
                <span className="text-white text-[20px] font-bold">
                  {info[1].total_income}
                </span>
                <span className="text-white text-[20px] font-bold">
                  Products
                </span>
              </div>
              <span className="absolute top-0 -mt-4 text-4xl font-bold text-gray-500">
                🥈
              </span>
              <span className="text-[16px] font-bold mt-2 text-gray-700">
                {info[1].city}
              </span>
            </div>
          ) : null}
          {/* المركز الأول */}
          {info.length>0?(
            <div className="relative flex flex-col justify-center items-center">
            <div className="flex items-center flex-col justify-center bg-gradient-to-r from-yellow-400 to-yellow-500 lg1:w-36 w-32 h-60 rounded-t-md shadow-lg transition-transform transform hover:scale-110">
              <span
                className={`${
                  lang === "En" ? "mr-1" : "ml-1"
                } text-white text-[20px] font-bold `}
              >
                {info[0].total_income}
              </span>
              <span className="text-white text-[20px] font-bold">Products</span>
            </div>
            <span className="absolute top-0 -mt-4 text-4xl font-bold text-yellow-700">
              🥇
            </span>
            <span className="text-[16px] font-bold mt-2 text-yellow-700">
              {info[0].city}
            </span>
          </div>
          ):(<div className="font-bold text-[16px]">{lang==='En'?'there are no cites':'لا يوجد مدن'}</div>)}
          

          {/* المركز الثالث */}
          {info.length>2?(
            <div className="relative flex flex-col justify-center items-center">
            <div className="flex flex-col items-center justify-center bg-gradient-to-r from-orange-300 to-orange-400 lg1:w-36 w-32 h-36 rounded-t-md shadow-md transition-transform transform hover:scale-105">
            <span className="text-white text-[20px] font-bold">{info[2].total_income}</span>
            <span className="text-white text-[20px] font-bold">Products</span>
            </div>
            <span className="font-bold mt-2 text-[16px] text-gray-700">{info[2].city}</span>
            <span className="absolute top-0 -mt-4 text-4xl font-bold text-orange-500">🥉</span>
          </div>
          ):(null)}
        </div>
      ) : (
        <div className=" flex items-center justify-center my-auto">
          <ClipLoader color="blue" size={100} />
        </div>
      )}
    </div>
  );
}
