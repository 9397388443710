import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { city, cityinDetail, language } from './atoms';
import { citiesByCountry } from './cites';

export default function CityDetails({disabled}) {
    const [lang,setLang] = useRecoilState(language);

    const [city, setCity] = useRecoilState(cityinDetail);
    
    const [selectedCountry, setSelectedCountry] = useState('SA');

    useEffect(() => {
        const foundCountry = Object.keys(citiesByCountry).find(country =>
            citiesByCountry[country].some(c => c.value === city)
        );

        if (foundCountry) {
            setSelectedCountry(foundCountry);
        }
    }, [city]);

    const handleCountryChange = (e) => {
        setSelectedCountry(e.target.value);
    };

    return (
        <div>
            <label className="block mb-2 font-medium">{lang==='En'?'City':'المدينة'}</label>
            <div className="flex">
                <select
                    disabled={disabled}
                    id="countries"
                    value={selectedCountry}
                    onChange={handleCountryChange}
                    className="flex-shrink-0 z-10 inline-flex py-2.5 px-4 text-sm font-medium border bg-blue-400 text-white rounded-s-lg focus:outline-none"
                >
                    <option className='text-black bg-gray-200 ' value="SA">
                        Saudi Arabia
                    </option>
                    <option className='text-black bg-gray-200 ' value="AE">
                        United Arab Emirates
                    </option>
                </select>
                <select
                    disabled={disabled}
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    id="cities"
                    className="bg-blue-400 border border-gray-300 text-white text-sm rounded-e-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                >
                    {citiesByCountry[selectedCountry].map((cityOption) => (
                        <option className='text-black bg-gray-200 ' key={cityOption.value} value={cityOption.value}>
                            {cityOption.label}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
}
