import axios from "axios"
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { ClipLoader } from "react-spinners";
import Swal from "sweetalert2";
import City from "../components/selectcity";
import { useRecoilState } from "recoil";
import { city, language, urlOfHost } from "../components/atoms";



export default function Contact () {
    const [lang,setLang] = useRecoilState(language);
    const [linkOfHost,] = useRecoilState(urlOfHost);
    const [myCity,setMyCity] = useRecoilState(city);
    const handleInputChange = (e, setErrorState) => {
        e.target.style.border = '1px solid #3b82f6'; 
        setErrorState(''); 
    };
    const [phoneError, setPhoneErr] = useState('');
    const [salesRep,setSalesRep] = useState();
    const [arabicNameErr, setArabicNameErr] = useState('');
    const [englishNameErr, setEnglishNameErr] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [dateErr, setDateErr] = useState('');
    const navigation = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [phone,setPhone] = useState('');
    const status = useRef();
    const source = useRef();
    const arabicName=useRef(null);
	const englishName=useRef();
	const date=useRef(null);
	const email=useRef();
	const contactType=useRef();
    const [salesREPs, setSalesREBPs] = useState ([]);
    const url = `${linkOfHost}/auth/managed-sales-reps/`;
    const getAllSalesREPs = async () => {
        try{
            const data = await axios
            .get(url)
            .then(res => {
                setSalesRep(res.data[0].id);
                setSalesREBPs(res.data);
            });
        } catch(err) {
            console.log(err)
        }
};
    useEffect(() => {
        getAllSalesREPs();
    }, [])
    return(
        <section className="flex flex-col items-center pt-6">
            {isLoading && (
                <div className="fixed inset-0 bg-[#D1C4E9] bg-opacity-50 flex items-center justify-center z-50">
                    <ClipLoader color="blue" loading={isLoading} size={150} />
                </div>
            )}
            <div className="w-full border-indigo-300 bg-white rounded-lg shadow border-[1px] md:mt-0 lg:max-w-xl sm:max-w-md xl:p-0">
                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight tracking-tight md:text-2xl">{lang==='En'?'Create A Contact':'إضافة عميل'}</h1>
                        <form className="space-y-4 md:space-y-6" method="POST" onSubmit={(e) => {
                            e.preventDefault();
                        }}>
                            <div className="flex gap-x-6 justify-between">
                                <div className="w-[47%]">
                                    <label htmlFor="arabic" className="block mb-2 text-sm font-medium">{lang==='En'?'Arabic Name (optional)':'الاسم العربي (اختياري)'}</label>
                                    <input
                                        ref={arabicName}
                                        onChange={(e)=>{handleInputChange(e,setArabicNameErr)}}
                                        type="text" name="arabicName" id="arabic"
                                        className="bg-gray-50 border border-gray-300 focus:outline-none focus:ring-primary-600 focus:border-primary-600 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5"
                                        placeholder={`.....`} required=""
                                    />
                                    <p className={`${lang==='En'?'pl-1 text-left':'text-right pr-1'} text-red-500 text-sm `}>{arabicNameErr}</p>
                                </div>
                                <div className="w-[47%]">
                                    <label htmlFor="english" className="block mb-2 text-sm font-medium">{lang==='En'?'English Name':'الاسم الإنكليزي'}</label>
                                    <input
                                        ref={englishName}
                                        onChange={(e) => {handleInputChange(e,setEnglishNameErr)}}
                                        type="text" name="englishName" id="english"
                                        className="bg-gray-50 border border-gray-300 focus:outline-none focus:ring-primary-600 focus:border-primary-600 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5"
                                        placeholder={`.....`} required=""
                                    />
                                    <p className={`${lang==='En'?'pl-1 text-left':'text-right pr-1'} text-red-500 text-sm `}>{englishNameErr}</p>
                                </div>
                            </div>
                            <div className="flex gap-x-6 justify-between">
                                <div className="w-[46%]">
                                    <label htmlFor="email" className="block mb-2 text-sm font-medium">{lang==='En'?'Email':'البريد الإلكتروني'}</label>
                                    <input
                                        onChange={(e)=>{handleInputChange(e,setEmailErr)}}
                                        ref={email}
                                        type="text" name="email" id="email"
                                        className="bg-gray-50 border border-gray-300 focus:outline-none focus:ring-primary-600 focus:border-primary-600 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5"
                                        placeholder="Email" required=""
                                    />
                                    <p className={`${lang==='En'?'pl-1 text-left':'text-right pr-1'} text-red-500 text-sm `}>{emailErr}</p>
                                </div>
                                <div className="w-[46%]">
                                    <label htmlFor="Date" className="block mb-2 text-sm font-medium">{lang==='En'?'Date (optional)':'التاريخ (اختياري)'}</label>
                                    <input
                                        ref={date}
                                        onChange={(e) => {handleInputChange(e,setEmailErr)}}
                                        type="Date" name="Date" id="date"
                                        className="bg-gray-50 border border-gray-300 focus:outline-none focus:ring-primary-600 focus:border-primary-600 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5"
                                        placeholder="Date" required=""
                                    />
                                    <p className={`${lang==='En'?'pl-1 text-left':'text-right pr-1'} text-red-500 text-sm`}>{dateErr}</p>
                                </div>
                            </div>
                            <div className="flex gap-x-6 justify-between">
                                <div className="w-[46%]">
                                    <label className="block mb-2 font-medium">{lang==='En'?'Source':'المصدر'}</label>
                                    <select ref={source} onChange={(e)=>{
                                    }} className=" w-full bg-blue-400 border-none text-white py-2 px-2 rounded-lg">
                                        <option value={'social media'} className=" text-black bg-gray-200 ">{lang==='En'?'Social Media':'تواصل اجتماعي'}</option>
                                        <option value={'old client'} className=" text-black bg-gray-200">{lang==='En'?'Old Client':'عميل قديم'}</option>
                                        <option value={'website'}  className=" text-black bg-gray-200 ">{lang==='En'?'Website':'موقع'}</option>
                                        <option value={'referral'} className=" text-black bg-gray-200 ">{lang==='En'?'Referral':'مُرشح'}</option>
                                        <option value={'other'} className=" text-black bg-gray-200 ">{lang==='En'?'Other':'غير ذلك'}</option>
                                    </select>
                                </div>
                                <div className="w-[46%] justify-end">
                                    <label className="block mb-2 font-medium">{lang==='En'?'Status':'الحالة'}</label>
                                    <select defaultValue={'active'} ref={status}  className=" w-full bg-blue-400 text-white py-2 px-2 rounded-lg">
                                        <option value={'active'} className=" text-black bg-gray-200 ">{lang==='en'?'Active':'نشط'}</option>
                                        <option value={'inactive'} className=" text-black bg-gray-200">{lang==='En'?'InActive':'غير نشط'}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="flex gap-x-6 justify-between">
                                <div className="w-[46%]">
                                    <label className="block mb-2 font-medium">{lang==='En'?'Contact type':'نوع العميل'}</label>
                                    <select ref={contactType} defaultValue={'individual'}  className=" w-full bg-blue-400 border-none text-white py-2 px-2 rounded-lg">
                                        <option value={'individual'} className=" text-black bg-gray-200 ">{lang==='En'?'Individual':'فردي'}</option>
                                        <option value={'broker'} className=" text-black bg-gray-200">{lang==='En'?'Broker':'وسيط'}</option>
                                        <option value={'company'}  className=" text-black bg-gray-200 ">{lang==='En'?'Company':'شركة'}</option>
                                    </select>
                                </div>
                                <div className="w-[46%] justify-end">
                                    <label className="block mb-2 font-medium">{lang==='En'?'AssginTo':'مُعين إلى :'}</label>
                                    <select defaultValue={salesRep} onChange={(e)=>{
                                        setSalesRep(e.target.value)
                                    }}  className=" w-full bg-blue-400 text-white py-2 px-2 rounded-lg">
                                        {salesREPs.map((salesRep) => (
                                        <option className="text-black bg-gray-200 " value={salesRep.id} key={salesRep.id}>{salesRep.full_name_en}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <City />
                            <div className="flex gap-x-6 justify-between">
                                <div>
                                    <div className="flex flex-col items-start w-full">
                                        <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900">
                                            {lang==='En'?'Phone Number':'رقم الهاتف'}
                                        </label>
                                        <PhoneInput
                                            country={'sy'}
                                            value={phone}
                                            onChange={(value,e)=>{
                                                setPhone(value);
                                                setPhoneErr('');
                                            }}
                                            inputProps={{
                                                name: 'phone',
                                                required: true,
                                                autoFocus: false
                                            }}
                                            inputClass="w-full bg-gray-50 border border-gray-300 rounded-lg p-2.5 text-gray-900 focus:outline-none focus:ring-1 focus:ring-primary-600 focus:border-primary-600"
                                            buttonClass="bg-white border border-gray-300"
                                            dropdownClass="absolute z-10 bg-white border border-gray-300 rounded-lg shadow-lg"
                                            containerClass="w-full"
                                            style={{
                                                direction: 'ltr', 
                                                textAlign: '', 
                                            }}
                                        />
                                        <p className={`${lang==='En'?'pl-1 text-left':'text-right pr-1'} text-red-500 text-sm`}>{phoneError}</p>
                                    </div>
                                </div>
                            </div>
                            
                            <div className=" flex flex-row justify-center ">
                            <button onClick={(e)=>{
                                console.log(salesRep)
                                setIsLoading(true)
                                axios.post(`${linkOfHost}/${lang==='En'?'en':'ar'}/contacts-services/contacts/`,{
                                    full_name_ar:arabicName.current.value,
                                    full_name_en:englishName.current.value,
                                    email:email.current.value,
                                    phone_number:`+${phone}`,
                                    city:myCity,
                                    contact_type:contactType.current.value,
                                    source:source.current.value,
                                    status:status.current.value,
                                    assign_to:salesRep,
                                    data_joined:date.current.value
                                }).then((res)=>{
                                    navigation(`/contacts`)
                                    Swal.fire({
                                        icon:"success",
                                        title:`${lang==='En'?'A Contact has been Added successfully.':'تمت إضافة عميل بنجاح .'}`,
                                        confirmButtonText : `${lang==='En'?'Ok':'حسنا'}`
                                    })                    
                                }).catch((err)=>{
                                    console.log(err.response)
                                    if(err.response){
                                        Object.keys(err.response.data).forEach(key => {
                                            if (key === "email") {
                                                const emailInput = document.getElementById("email");
                                                emailInput.style.border = '1px solid red';
                                                setEmailErr(err.response.data.email[0]);
                                            }
                                            if (key === "full_name_en") {
                                                const englishNameInput = document.getElementById("english");
                                                englishNameInput.style.border = '1px solid red';
                                                setEnglishNameErr(err.response.data.full_name_en[0]);
                                            }
                                            if (key === "phone_number") {
                                                setPhoneErr(err.response.data.phone_number[0]);
                                            }
                                        })};
                                }).finally(()=>{
                                    setIsLoading(false);
                                })
                            }} type="submit" name="submit" id="submit"
                            className="w-full text-white bg-blue-500 hover:bg-blue-600 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                {lang==='En'?'Create':'إنشاء'}
                            </button>
                            </div>
                        </form>
                    </div>
            </div>
        </section>
    )
}