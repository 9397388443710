import React, { useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import { useRecoilState } from "recoil";
import { clearPhoneInput, language, phone, phoneErr } from "./atoms";

export default function PhoneInputField() {
    const [lang,setLang] = useRecoilState(language);
    const [phoneError,setPhoneErr] = useRecoilState(phoneErr)
    const [myphone,setMyphone] = useRecoilState(phone);
    const [clearPhone,setClearPhone] = useRecoilState(clearPhoneInput);

    useEffect(()=>{
        
},[clearPhone]);
    return (
        <div className="flex flex-col items-start w-full">
            <label htmlFor="phone" className="block text-left mb-2 text-sm font-medium text-gray-900">
                {lang==='En'?'Phone Number':'رقم الهاتف'}
            </label>
            <PhoneInput
                country={'sy'}
                value={myphone}
                onChange={setMyphone}
                inputProps={{
                    name: 'phone',
                    required: true,
                    autoFocus: true
                }}
                inputClass="w-full bg-gray-50 border border-gray-300 rounded-lg p-2.5 text-gray-900 focus:outline-none focus:ring-1 focus:ring-primary-600 focus:border-primary-600"
                buttonClass="bg-white border border-gray-300"
                dropdownClass="absolute z-10 bg-white border border-gray-300 rounded-lg shadow-lg"
                containerClass="w-full"
                style={{
                    direction: 'ltr', 
                    textAlign: '', 
                }}
            />
            <p className={`${lang==='En'?'text-left':'text-right'} text-red-500`}>{phoneError}</p>
        </div>
    );
}
