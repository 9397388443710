import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import Swal from "sweetalert2";
import { language, urlOfHost } from "../components/atoms";
import { useRecoilState } from "recoil";

export default function DetailOfReminder (){
    const [loading,setLoading] = useState(false);
    const [lang,setLang] = useRecoilState(language);
    const [linkOfHost,] = useRecoilState(urlOfHost);
    const location = useLocation();
    const fromPage = location.state?.from;
    const dealId = location.state?.dealId;
    const navigation = useNavigate();
    const [titleErr, setTitleErr] = useState('');
    const [descriptionErr, setDescriptionErr] = useState('');
    const [dateErr, setDateErr] = useState('');
    const [emailErr, setEmailErr]= useState ('');
    const params = useParams();
    const [title,setTitle] = useState('');
    const [Description,setDescription] = useState('');
    const [email,setEmail] = useState('');
    const [date,setDate] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const url = `${linkOfHost}/${lang==='En'?'en':'ar'}/contacts-services/contacts/${params.contactId}/reminders/${params.reminderId}`;
    const handleInputChange = (e,setState, setErrorState) => {
    e.target.style.border = '1px solid #3b82f6'; 
    setErrorState(''); 
    setState(e.target.value);
};
    const handleDetail = async () => {
        try{
            setLoading(true);
            const data = await axios
            .get(url)
            .then(res => {
                setTitle(res.data.title);
                setDescription(res.data.description);
                setEmail(res.data.email);
                setDate(res.data.date);
            });
        } catch(err) {
            console.log(err)
        }finally{
            setLoading(false);
        }
};
const handelUpdate = () => {
    setIsLoading(true);
    axios
    .put(
        `${linkOfHost}/${lang==='En'?'en':'ar'}/contacts-services/contacts/${params.contactId}/reminders/${params.reminderId}`,
        {
            title:title.current.value,
            description:Description.current.value,
            email:email.current.value,
            date:date.current.value
        }
    )
    .then((res) => {
        Swal.fire({
        icon:"success",
        title:`${lang==='En'?'Modified Successfully':'تم التعديل بنجاح'}`
    })
    if(fromPage==='contact'){
        navigation(`/contacts/contactDetail/${params.contactId}/reminders`)
    }else if(fromPage==='deal'){
        navigation(`/contacts/contactDetail/${params.contactId}/deals/dealDetail/${dealId}/reminders`)
    }
    })
    .catch((err) => {
        if (err.response) {
        Object.keys(err.response.data).forEach((key)=>{
            if(key==='title'){
            const titleInput = document.getElementById("title");
            titleInput.style.border = '1px solid #CE4343';
            setTitleErr(err.response.data.title[0])
            }else if (key==='description'){
            const noteInput = document.getElementById("description");
            noteInput.style.border = '1px solid #CE4343';
            setDescriptionErr(err.response.data.description[0]);
            }else if(key==="email"){
                const emailInput = document.getElementById('email');
                emailInput.style.border = '1px solid #CE4343';
                setEmailErr(err.response.data.email[0]);
            }else if(key==='date'){
                const dateInput = document.getElementById('date');
                dateInput.style.border = '1px solid #CE4343';
                setDateErr(err.response.data.date[0]);
            }
        })
        } else {
        Swal.fire({
            title:`${err.message}`,
            icon:"error"
        })
        }
    })
    .finally(() => {
        setIsLoading(false);
    });
};

    useEffect(()=>{
        handleDetail();
    },[])
    return (
        <center className="flex flex-col pt-10 min-h-screen items-center">
            {loading?(
                <div className="flex justify-center py-4">
                <ClipLoader color="blue" loading={loading} size={60} />
              </div>
            ):(
                <div className="w-full max-w-lg border-[1px] border-indigo-300 mb-1">
                {isLoading && (
                <div className="fixed inset-0 bg-[#D1C4E9] bg-opacity-50 flex items-center justify-center z-50">
                    <ClipLoader color="blue" loading={isLoading} size={150} />
                </div>
                )}
                <form
                method="POST"
                onSubmit={(e) => {
                    e.preventDefault();
                }}
                className="bg-white shadow-md rounded px-8 pt-6 pb-8"
                >
                <h1 className="mb-5 font-bold leading-tight tracking-tight md:text-xl">
                    {lang==='En'?'Edit a Reminder':'تعديل التذكير'}
                </h1>
                <div className="mb-4">
                    <label
                    className={`block ${lang==='En'?'text-left':'text-right'} text-gray-700 text-sm font-openSansBold mb-2`}
                    htmlFor="noteTitle"
                    >
                    {lang==='En'?'Title of Reminder':'عنوان التذكير'}
                    </label>
                    <input
                    onChange={(e)=>{
                        handleInputChange(e,setTitle,setTitleErr);
                    }}
                    value={title}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 focus:outline-none"
                    id="title"
                    type="text"
                    placeholder={`${lang==='En'?'Enter a title':'أدخل العنوان'}`}
                    />
                    <p className={`${lang==='Em'?'text-left':'text-right'} text-[#CE4343] text-sm pl-1`}>{titleErr}</p>
                </div>
                <div className="mb-6">
                    <label
                    className={`block ${lang==='En'?'text-left':'text-right'} text-gray-700 text-sm font-openSansBold mb-2`}
                    htmlFor="noteDescription"
                    >
                    {lang==='En'?'Description of Reminder':'وصف التذكير'}
                    </label>
                <textarea
                    onChange={(e)=>{
                        handleInputChange(e,setDescription,setDescriptionErr);
                    }}
                    value={Description}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 focus:outline-none"
                    id="description"
                    type="text"
                    placeholder={`${lang==='En'?'Enter a description':'أدخل الوصف'}`}
                />
                <p className={`${lang==='Em'?'text-left':'text-right'} text-[#CE4343] text-sm pl-1`}>{descriptionErr}</p>
                </div>
                <div className="mb-6">
                    <label
                    className={`block  ${lang==='En'?'text-left':'text-right'} text-gray-700 text-sm font-openSansBold mb-2`}
                    htmlFor="email"
                    >
                    {lang==='En'?'Email':'البريد الإلكتروني'}
                    </label>
                    <input
                    onChange={(e)=>{
                        handleInputChange(e,setEmail,setEmailErr);
                    }}
                    value={email}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 focus:outline-none"
                    id="email"
                    type="email"
                    placeholder={`${lang==='En'?'Enter an Email':'أدخل البريد الإلكتروني'}`}
                    />
                    <p className={`${lang==='Em'?'text-left':'text-right'} text-[#CE4343] text-sm pl-1`}>{emailErr}</p>
                </div>
                <div className="mb-6">
                <label
                    className={`block ${lang==='En'?'text-left':'text-right'} text-gray-700 text-sm font-openSansBold mb-2`}
                    htmlFor="date"
                >
                    {lang==='En'?'Date of Reminder':'تاريخ التذكير'}
                    </label>
                <input
                    onChange={(e)=>{
                        handleInputChange(e,setDate,setDateErr);
                    }}
                    value={date}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 focus:outline-none"
                    id="date"
                    type="date"
                    />
                    <p className={`${lang==='Em'?'text-left':'text-right'} text-[#CE4343] text-sm pl-1`}>{dateErr}</p>
                </div>
                <div className="flex items-center">
                    <button
                    onClick={handelUpdate}
                    className={`bg-blue-500 ${lang==='En'?'mr-5':'ml-5'} hover:bg-blue-600 text-white font-openSansBold py-2 px-4 rounded focus:outline-none`}
                    type="button"
                    >
                    {lang==='En'?'save':'حفظ'}
                    </button>
                    <button onClick={(e)=>{
                        setTitle('');
                        setDate('');
                        setEmail('');
                        setDescription('');
                    }}
                        className="px-4 py-2 bg-red-500 hover:bg-red-600 font-medium rounded-md text-white">
                            {lang==='En'?'Reset':'اعادة تعيين'}
                    </button>
                </div>
                </form>
            </div>
            )}
        </center>
    );
}