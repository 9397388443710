import { Outlet } from "react-router-dom";
import SideBar from "../pages/sidebar";
import { useRecoilState } from "recoil";
import { globalLoading, language } from "../components/atoms";
import { BeatLoader} from "react-spinners";
import { useEffect } from "react";

export default function Home() {
    const [lang,setLang] = useRecoilState(language);
    const [isLoading , setIsLoading] = useRecoilState(globalLoading);
    useEffect(() => {
        if(sessionStorage.getItem('lang')){
            setLang(sessionStorage.getItem('lang'))
        }
      }, []);
    return (
        <>
         {isLoading && (
                <div className="fixed inset-0 bg-[#D1C4E9] bg-opacity-50 flex items-center justify-center z-50">
                    <BeatLoader color="blue" loading={isLoading} size={20} />
                </div>
            )}
        <div dir={`${lang==='En'?'ltr':'rtl'}`} className={`flex min-h-screen`}>
            <SideBar />
            <main className="flex-1 bg-white">
                <Outlet />
            </main>
        </div>
        </>
    );
}