import axios from "axios";
import BarChart from "../components/barChart";
import Card from "../components/card";
import Doughnut from "../components/doughnutChart";
import SuadiaArabiaMab from "../components/mapOfSaudiaArabia";
import Pie from "../components/pieChart";
import SourceChart from "../components/sourceChart";
import TopThree from "../components/top3cites";
import TypeChart from "../components/typeChart";
import team from "../icons/IMG_20241019_214254_859.png"
import emp from "../icons/emp.png"
import { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { useRecoilState } from "recoil";
import { language, urlOfHost } from "../components/atoms";

export default function DashBoard (){
    const [lang,setLang] = useRecoilState(language);
    const [linkOfHost,] = useRecoilState(urlOfHost);
    const [loadingEmployee,setLoadingEmployee] = useState(false)
    const [employeeOfMonth,setEmployeeOfMonth] = useState({});
    const [loadingTeam,setLoadingTeam] = useState(false)
    const [teamOfMonth,setTeamOfMonth] = useState({});
    const url = `${linkOfHost}/statistics/sales-representative-of-the-month/`
    const url1 = `${linkOfHost}/statistics/team-of-the-month/`
    const getInfo = async () => {
        try {
            setLoadingEmployee(false);
            const response = await axios.get(url);
            const data = response.data;
            setEmployeeOfMonth(data)
            setLoadingEmployee(true);
        } catch (err) {
          console.log(err);
        }
      };
    const getInfoOfTeam = async () => {
        try {
            setLoadingEmployee(false);
            const response = await axios.get(url1);
            const data = response.data;
            setTeamOfMonth(data)
            setLoadingTeam(true);
        } catch (err) {
          console.log(err);
        }
      };
      useEffect(()=>{
        getInfoOfTeam();
        getInfo();
      },[])
    return(
        <div className="mr-3 mt-5">
            <div className="flex flex-col">
                <p className="mb-2 font-bold text-center text-[18px]">{lang==='En'?' Chart showing the number of Deals':'الرسم البياني يوضح عدد الصفقات'}</p>
                <BarChart />
            </div>
                <hr className="h-[2px] bg-slate-200 w-full my-5 z-0"/>
                <div className="flex flex-row justify-between z-0">
                    <div className="w-[47%] z-0">
                        <p className="mb-2 font-bold text-[18px]">{lang==='En'?'Map to show the number of Clients in each city':'خريطة توضح عدد العملاء في كل مدينة'} </p>
                        <SuadiaArabiaMab />
                    </div>
                    <div className="w-[47%]">
                        <p className="mb-2 font-bold text-center text-[22px]">{lang==='En'?'Top 3 selling cities':'أفضل 3 مدن مبيعا'}</p>
                        <div className=" flex flex-row justify-center items-center justify-items-center">
                            <TopThree />
                        </div>
                    </div>
                </div>
                <hr className="h-[2px] bg-slate-200 w-full my-5"/>
                <div className=" flex flex-row gap-x-16 justify-between mb-5">
                <div className="w-[47%]">
                    <div className="w-full font-bold text-[20px] rounded-lg shadow-lg">
                        <p className="text-center mt-4 -mb-8">{lang==='En'?'Number of deals at each stage':'عدد الصفقات في كل مرحلة'}</p>
                        <Doughnut />
                    </div>
                </div>
                <div className="w-[47%]">
                    <div className="w-full font-bold text-[20px] rounded-lg shadow-lg">
                        <p className="text-center mt-4 -mb-8">{lang==='En'?' Number of rejected deals for each reason':'عدد الصفقات المرفوضة لكل سبب'}</p>
                        <Pie />
                    </div>
                </div>
            </div>
            <div className=" flex flex-row gap-x-16 justify-between mb-5">
                <div className="w-[47%]">
                    <div className="w-full font-bold text-[20px] rounded-lg shadow-lg">
                        <p className="text-center mt-4 -mb-8">{lang==='En'?'Number of Contacts at each Type':'عدد العملاء في كل نوع'}</p>
                        <TypeChart />
                    </div>
                </div>
                <div className="w-[47%]">
                    <div className="w-full font-bold text-[20px] rounded-lg shadow-lg">
                        <p className="text-center mt-4 -mb-8">{lang==='En'?'Number of Contacts for each Source':'عدد العملاء لكل مصدر'}</p>
                        <SourceChart />
                    </div>
                </div>
            </div>
            <hr className="h-[2px] bg-slate-200 w-full my-5"/>
            <div className=" flex flex-row gap-x-16 justify-between mb-5">
                <div className="w-full font-bold text-[22px]">
                    <p className="text-center mb-4">{lang==='En'?'Team of The month':'فريق الشهر'}</p>
                    {loadingTeam?(<Card img={team} name={teamOfMonth.name} total={teamOfMonth.total} deals={teamOfMonth.closed_deals} />)
                    :(<div className=" flex items-center justify-center">
                        <ClipLoader color="blue" size={100} />
                    </div>)}
                </div>
                <div className="w-full font-bold text-[22px]">
                    <p className="text-center mb-4">{lang==='En'?'Employee of The month':'موظف الشهر'}</p>
                    {loadingEmployee?(<Card img={emp} name={employeeOfMonth.full_name_en} total={employeeOfMonth.total} deals={employeeOfMonth.closed_deals} />)
                    :(<div className=" flex items-center justify-center">
                        <ClipLoader color="blue" size={100} />
                    </div>)}
                </div>
            </div>       
        </div>
    )
}