import axios from "axios";
import { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import Swal from "sweetalert2";
import { language, urlOfHost } from "../components/atoms";
import { useRecoilState } from "recoil";

export default function CreateDeal() {
  const [lang,setLang] = useRecoilState(language);

  const [linkOfHost,] = useRecoilState(urlOfHost);
  const navigation = useNavigate();
  const dealNumber = useRef();
  const [numberErr, setNumberErr] = useState('');
  const [desErr, setDesErr] = useState('');
  const params = useParams();
  const dealDescription = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const handleInputChange = (e, setErrorState) => {
    e.target.style.border = '1px solid #3b82f6'; 
    setErrorState(''); 
};

  const uploadFiles = () => {
    setIsLoading(true);
    axios
      .post(
        `${linkOfHost}/${lang==='En'?'en':'ar'}/contacts-services/contacts/${params.contactId}/deals/`,{
            number : dealNumber.current.value,
            description : dealDescription.current.value,
        }
      )
      .then((res) => {
        Swal.fire({
          icon:"success",
          title:`${lang==='En'?'a Deal has been successfully created.':'تم انشاء الصفقة بنجاح'}`,
          confirmButtonText : "حسنا"
      })
      navigation(`/contacts/contactDetail/${params.contactId}/dealsOfContact`)
      })
      .catch((err) => {
        if (err.response) {
            Object.keys(err.response.data).forEach((key)=>{
                if(key==='number'){
                    const titleInput = document.getElementById("dealNumber");
                    titleInput.style.border = '1px solid #CE4343';
                    setNumberErr(err.response.data.number[0])
                }else if (key==='description'){
                    const noteInput = document.getElementById("dealDescripition");
                    noteInput.style.border = '1px solid #CE4343';
                    setDesErr(err.response.data.description[0]);
                }
            })
        } else {
        Swal.fire({
            title:`${err.message}`,
            icon:"error"
            })
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <center className="flex flex-col pt-10 min-h-screen items-center">
      <div className="w-full max-w-lg border-[1px] border-indigo-300 mb-1">
        {isLoading && (
          <div className="fixed inset-0 bg-[#D1C4E9] bg-opacity-50 flex items-center justify-center z-50">
            <ClipLoader color="blue" loading={isLoading} size={150} />
          </div>
        )}
        <form
          method="POST"
          onSubmit={(e) => {
            e.preventDefault();
          }}
          className="bg-white shadow-md rounded px-8 pt-6 pb-8"
        >
          <h1 className="mb-5 font-bold leading-tight tracking-tight md:text-xl">
            {lang==='En'?'Create a Deal':'إنشاء إستمارة'}
          </h1>
          <div className="mb-4">
            <label
              className={`${lang==='En'?'text-left':'text-right'} block  text-gray-700 text-sm font-openSansBold mb-2`}
              htmlFor="dealNum"
            >
                {lang==='En'?'Deal Number':'رقم الإستمارة'}
            </label>
            <input
              onChange={(e)=>{
                handleInputChange(e,setNumberErr);
              }}
              ref={dealNumber}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 focus:outline-none"
              id="dealNumber"
              type="text"
              placeholder={`${lang==='En'?'Enter a Deal Number':'أدخل رقم الإستمارة'}`}
            />
            <p className={`${lang==='En'?'pl-1 text-left':'text-right pr-1'} text-red-500 text-sm`}>{numberErr}</p>
          </div>
          <div className="mb-6">
            <label
              className={`${lang==='En'?'text-left':'text-right'} block text-gray-700 text-sm font-openSansBold mb-2`}
              htmlFor="dealDescription"
            >
                {lang==='En'?'Deal Description':'وصف الإستمارة'}
            </label>
            <textarea
              onChange={(e)=>{
                handleInputChange(e,setDesErr);
              }}
              ref={dealDescription}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 focus:outline-none"
              id="dealDescripition"
              type="text"
              placeholder={`${lang==='En'?'Enter a Deal Description':'أدخل وصف الإستمارة'}`}
            />
            <p className={`${lang==='Em'?'text-left':'text-right'} text-[#CE4343] text-sm pl-1`}>{desErr}</p>
            </div>
            <div className="flex items-center justify-between">
            <button
                onClick={uploadFiles}
                className="bg-blue-500 hover:bg-blue-600 text-white font-openSansBold py-2 px-4 rounded focus:outline-none"
                type="button"
            >
                {lang==='En'?'create':'إنشاء'}
            </button>
          </div>
        </form>
      </div>
    </center>
  );
}
