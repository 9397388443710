import React, { useState, useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { language, leaderName, urlOfHost } from './atoms';
import axios from 'axios';

const LeaderFilter = (props) => {
  const [lang,setLang] = useRecoilState(language);
  const [linkOfHost,] = useRecoilState(urlOfHost);
    const [Leaders,setLeaders] = useState([]);
    const [MyLeaderNmae,setMyLeaderName] = useRecoilState(leaderName);
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const url = `${linkOfHost}/auth/leaders/`;
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        }
    };
    const getAllLeaders = () => {
        axios.get(url).then((res) => {
            setLeaders(res.data);
        }).catch(err => {
            console.log(err);
        });
    };

  useEffect(() => {
    getAllLeaders();
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  useEffect(()=>{
    if(lang!=='En'){
      setMyLeaderName('قائد الفريق');
    }else{
      setMyLeaderName('Lead Name');
    }
  },[lang])

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        id="dropdownAvatarNameButton"
        onClick={toggleDropdown}
        className="flex items-center text-sm pe-1 font-medium text-white md:me-0"
        type="button"
      >
        <span className="sr-only">Open Sources menu</span>
        <span className="font-bold">{MyLeaderNmae}</span>
        <svg
          className="w-2.5 h-2.5 ms-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>

      {isOpen && (
        <div
          id="dropdownAvatarName"
          className={`absolute top-7 ${lang==='En'?'left-[-13px]':'right-[-13px]'}  z-10 bg-gray-50 divide-y divide-gray-100 rounded-lg shadow w-44`}
        >
            <ul className="py-2 text-sm text-gray-700">
            {
                Leaders.map((leader)=>{
                    return(
                        <li onClick={(e)=>{
                            props.clearFilter(true)
                            props.leaderValue(leader.id)
                            setMyLeaderName(leader.full_name_en)
                            setIsOpen(false)
                        }} className=' hover:bg-gray-100 cursor-pointer'>
                            <button
                                className="block px-4 py-2 text-gray-900"
                            >
                                {leader.full_name_en}
                            </button>
                        </li>
                    )
                })
            }
            
          </ul>
        </div>
      )}
    </div>
  );
};

export default LeaderFilter;
