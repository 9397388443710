import { useRecoilState } from "recoil";
import { language, role } from "./atoms";

export default function SeclectRole (){
    const [lang,setLang] = useRecoilState(language);
    const [myRole,setMyRole] = useRecoilState(role);
    return(
        <div className="w-[46%] justify-end">
            <label className="block mb-2 font-medium">{lang==='En'?'Role':'الدور'}</label>
            <select defaultValue={Number(1)} onChange={(e)=>{
                setMyRole(e.target.value);
            }} className=" w-full bg-blue-500 text-white py-2 px-2 rounded-lg">
                <option value={Number(1)} className=" text-black bg-gray-200 ">{lang==='En'?'Customer-Servcies':'خادم عملاء'}</option>
                <option value={Number(4)} className=" text-black bg-gray-200">{lang==='En'?'Admin':'مدير'}</option>
                <option value={Number(3)}  className=" text-black bg-gray-200 ">{lang==='En'?'Sales-Manger':'مدير مبيعات'}</option>
                <option value={Number(2)} className=" text-black bg-gray-200 ">{lang==='En'?'Sales-REB':'مندوب مبيعات'}</option>
            </select>
        </div>
    )
}