import { atom } from "recoil";
const country = atom({
    key:"country",
    default:"AF"
})
const role = atom({
    key:"role",
    default:''
})
const phone = atom({
    key:"phone",
    default:""
})
const phoneErr = atom({
    key:"err",
    default:""
})
const clearPhoneInput = atom({
    key:"clear",
    default:false
})
const verifyEmail = atom({
    key:"verify",
    default:""
})
const resetLink = atom({
    key:"Link",
    default:""
})

const deletRow = atom({
    key: 'deletrow',
    default:false, 
});
const numberOfTeams = atom({
    key: 'numberOfTeams', 
    default:0, 
});
const offsetTeams = atom({
    key: 'offsetTeams', 
    default:0, 
});
const displayedCountAtom = atom({
    key: 'displyedCountAtom',
    default:0, 
});
const city = atom({
    key: 'city', 
    default: 'ABHA' 
});
const cityinDetail = atom({
    key: 'cityInDetail', 
    default: '', 
});
const rowDeletedOfContacts = atom({
    key: 'rowDeletedOfcontacts', 
    default: false, 
});
const leaderName = atom({
    key: 'leaderName', 
    default:'Leader Name',
});
const nameOfTeamLeader = atom({
    key: 'nameOfTeamLeader', 
    default:'',
});
const statusOfDeal = atom({
    key: 'statust',
    default:'default', 
});
const rejectedDateAtom = atom({
    key: 'DateOfRejected',
    default:'', 
});
const language = atom({
    key: 'language',
    default:'Ar', 
});

const link = atom({ //https://www.crm.axisorch.com     http://localhost:3000   https://axisorch-crm.vercel.app
    key: 'localhostUrl', 
    default:'http://localhost:3000', 
});
const urlOfHost = atom({
    key: 'urlOfHost', 
    default:'https://jaberissa.pythonanywhere.com', 
});
const globalLoading = atom({ 
    key: 'globalLoading', 
    default:false, 
});
const fullName = atom({ 
    key: 'fullName', 
    default:'', 
});



export {role,country,phone,phoneErr,clearPhoneInput,verifyEmail,resetLink,deletRow,numberOfTeams,offsetTeams,displayedCountAtom,city
,rowDeletedOfContacts,cityinDetail,leaderName,statusOfDeal,rejectedDateAtom,link,nameOfTeamLeader,urlOfHost,language,globalLoading,fullName
}